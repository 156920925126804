<template>
  <div>
    <NavBar class="nav-bar">
      <div slot="title">我的收益</div>
    </NavBar>
    <div class="layout">
      <div class="tab-class">
        <div :class="{ 'bootom-color': active === 1 }" @click="clickTime(1)">
          近7日
        </div>
        <div :class="{ 'bootom-color': active === 2 }" @click="clickTime(2)">
          近30天
        </div>
        <div :class="{ 'bootom-color': active === 3 }" @click="clickTime(3)">
          全部
        </div>
      </div>
      <div class="my-money">
        <div class="money-layout">
          <div>我的收益</div>
          <span>￥{{ fourFloat(totalMoney) }}</span>
        </div>
      </div>
      <div class="earning-detail">
        <div class="earning-title">收益明细</div>
        <div class="earning-card">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="order-list" v-for="(item, index) in list" :key="index">
              <img :src="item.shop_logo" class="head-img" />
              <div class="order-info">
                <p class="goods-name">{{ item.goods_name }}</p>
                <p class="price">
                  <span>价格：￥</span>
                  <span>{{ item.total_money }}</span>
                </p>
                <p class="common-color">订单号：{{ item.order_sn }}</p>
                <p class="common-color">{{ item.created_at }}</p>
              </div>
              <div class="money-info" v-if="item.commission * 1 > 0">
                <p class="hire-return">收益</p>
                <p class="hire-price">
                  <span>￥</span>
                  <span>{{ fourFloat(item.commission) }}</span>
                </p>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getMyShopSalesList, getMyShopSalesTotal } from "@/api/shop";
import tools from "@/common/js/tools";
export default {
  name: "shop-earnings",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      finished: false,
      active: 1,
      list: [],
      page: 1,
      total: 100,
      totalMoney: 0,
      isAjax: false,
    };
  },
  mounted() {
    this.getMySalesTotal();
  },
  methods: {
    onLoad() {
      this.startOrderList();
    },
    clickTime(num) {
      this.active = num;
      this.startOrderList();
    },
    async getMySalesTotal() {
      const ret = await getMyShopSalesTotal();
      if (ret.code * 1 === 1) {
        this.totalMoney = ret.data.totalMoney;
      }
    },
    startOrderList() {
      //初始化订单列表
      this.list = [];
      this.page = 1;
      this.isAjax = false;
      this.total = 100;
      this.getMyShopSalesList();
    },
    async getMyShopSalesList() {
      //获取订单列表数据
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await getMyShopSalesList({
        type: this.active,
        page: this.page,
      });
      console.log(ret);
      if (ret.code * 1 == 1) {
        this.page++;
        this.list = [...this.list, ...ret.data.items];
        this.total = ret.data.total;
      }
      this.loading = false;
      if (this.list.length >= this.total) {
        this.finished = true;
      }
      this.isAjax = false;
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
    },
    fourFloat(num) {
      return tools.fourFloat(num);
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  background-color: #fff;
}
.tab-class {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  font-size: 18px;
  color: #6f6f6f;
}
.bootom-color {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  border-bottom: 3px solid rgb(231, 14, 14);
}
.layout {
  padding-top: 54px;
}
.my-money {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 154px;
  background: url("~@/assets/my/background-img-5@2x.png") no-repeat center /
    100%;
  .money-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    div {
      font-size: 18px;
    }
    span {
      font-size: 31px;
      font-weight: 600;
    }
  }
}
.earning-detail {
  padding: 12px;
  .earning-title {
    font-size: 18px;
    font-weight: 500;
  }
}
.earning-card {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 16px;
  overflow: hidden;
}
.table-title {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-bottom: 10px;
  font-size: 17px;
  span {
    flex: 1;
    &:nth-child(2) {
      text-align: center;
    }
    &:last-child {
      text-align: right;
    }
  }
}
.order-list {
  position: relative;
  display: flex;
  padding: 16px 12px 13px 15px;
  border-bottom: 1px solid #ededed;
  p {
    margin: 0;
    color: #aaaaaa;
    margin-bottom: 3px;
  }
  .head-img {
    display: block;
    width: 39px;
    height: 39px;
  }
  .order-info {
    width: 184px;
    margin-left: 11px;
    .goods-name {
      width: 100%;
      font-size: 17px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .price {
      font-size: 14px;
    }
    .common-color {
      font-size: 13px;
    }
  }
  .money-info {
    position: absolute;
    top: 16px;
    right: 12px;
    text-align: right;
    .hire-return {
      font-size: 14px;
      color: #000;
    }
    .hire-price {
      font-size: 17px;
      color: #fd6d1f;
      margin-top: 16px;
    }
  }
}
</style>
