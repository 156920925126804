import request from './ajax'
// 店铺模块
const shopUrl = '/api/shop/'
// 店铺分类
export const getShopClass = (data) => request(shopUrl + 'class', 'post', { ...data })
// 店铺列表
export const getShopList = (data) => request(shopUrl + 'list', 'post', { ...data })
// 店铺详情
export const getShopDetails = (data) => request(shopUrl + 'detail', 'post', { ...data })
// 收藏店铺
export const setShopCollect = (data) => request(shopUrl + 'collect', 'post', { ...data })
// 收藏列表
export const getCollectList = (data) => request(shopUrl + 'collect_list', 'post', { ...data })
// 分享店铺
export const getShareShop = (data) => request(shopUrl + 'share', 'post', { ...data })
// 注册店铺
export const registerShops = (data) => request(shopUrl + 'register', 'post', { ...data })
// 店铺团队信息
export const getShopTeamInfo = (data) => request(shopUrl + 'shop_team', 'post', { ...data })
// 推荐店铺列表
export const getShopTeamList = (data) => request(shopUrl + 'team_list', 'post', { ...data })
//获取店铺余额
export const getMyShopMoneyInfo = (data) => request(shopUrl + 'shop_money', 'post', { ...data })
// 获取推荐店铺信息
export const getShopCode = (data) => request(shopUrl + 'shop_code', 'post', { ...data })
export const getMyShop = (data) => request(shopUrl + 'my_shop', 'post', { ...data })
export const getMyShopSalesTotal = (data) => request(shopUrl + 'sales', 'post', { ...data })
export const getMyShopSalesList = (data) => request(shopUrl + 'sales_list', 'post', { ...data })
export const getShopSet = (data) => request(shopUrl + 'get-set', 'post', { ...data })
export const setShopSet = (data) => request(shopUrl + 'set', 'post', { ...data })
export const getShopAccredit = (data) => request(shopUrl + 'accredit-get', 'post', { ...data })
export const setShopAccredit = (data) => request(shopUrl + 'accredit-set', 'post', { ...data })
export const getShopCityList = (data) => request(shopUrl + 'shop-city', 'post', { ...data })
// 收款码
export const getShopPay = (data) => request(shopUrl + 'shop-pay', 'post', { ...data })
// 获取店铺优惠卷
export const getShopcoupon = (data) => request(shopUrl + 'coupon', 'post', { ...data })
// 店铺主页-优惠卷商品(活动商品)
export const getCouponGoods = (data) => request(shopUrl + 'coupon_goods', 'post', { ...data })
